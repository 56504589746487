<template>
  <v-navigation-drawer v-if="this.$root.user.is_logged_in" app clipped dark color="secondary" v-model="drawer"
    :mini-variant.sync="is_mini">
    <v-list dense>
      <module-navigation-menu-tag ref="sidebar_left_module_navigation" :initially_open="true"
        :class="is_open ? `is_open` : ''" :html_tag="iframe_main_menu_tag"
        root_html_element_id="treeview-module-navigation" :key="'main-' + menu_tag_key"
        header_icon_closed="mdi-cube-outline" header_icon_open="mdi-cube-unfolded" 
        @empty_state_changed="set_close_navigation_group_after_route_change"
        />
      <div v-for="(menu_group, index) in $root.main_menu.by_group" :key="index">
        <navigation-menu-group :close_after_route_change="close_navigation_group_after_route_change" :menu_group="menu_group" />
      </div>
      <module-navigation-menu-tag :open="false" :class="is_open ? `is_open` : ''"
        :html_tag="iframe_documentation_menu_tag" root_html_element_id="treeview-documentation"
        :key="'documentation-' + menu_tag_key" header_icon_closed="mdi-bookshelf"
        header_icon_open="mdi-book-open-variant" />
      <v-list-item v-if="$root.user.permissions.infoboard && $root.config.infoboard.enabled" link :to="{ name: 'IB' }">
        <v-list-item-icon>
          <v-icon>mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            INFOBOARD
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import ModuleNavigationMenuTag from "components/sidebar/partials/module_navigation_menu";
import NavigationMenuGroup from "components/sidebar/partials/navigation_menu_group"

export default {
  components: { ModuleNavigationMenuTag, NavigationMenuGroup },
  props: {
    is_open: Boolean,
    showInfos: Boolean,
  },

  data: () => ({
    iframe_main_menu_tag: '<div ref="childMenu" id="treeview-module-navigation"></div>',
    iframe_documentation_menu_tag: '<div ref="childDocumentation" id="treeview-documentation"></div>',
    menu_tag_key: 0,
    close_navigation_group_after_route_change: false
  }),

  computed: {
    drawer: {
      get() {
        return this.is_open
      },
      set(value) {
        this.$emit("update_drawer", value);
      }
    },
    is_mini: {
      get() {
        return !this.is_open;
      },
      set(value) {
        this.$emit("update_drawer", value);
      },
    },
  },

  created() {
    this.$root.$router.beforeEach((to, from, next) => {
      this.menu_tag_key += 1;
      next();
    });
  },

  methods: {
    set_close_navigation_group_after_route_change(empty){
      this.close_navigation_group_after_route_change = !empty
    }
  },
};
</script>
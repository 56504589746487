<template>
  <v-dialog v-model="show" scrollable max-width="40rem" content-class="my-custom-dialog" >
   
      <v-card class="warning" >
        <v-card-title >
          <div class="white--text"><v-icon dark>mdi-email-outline</v-icon> {{ $t('infoboard.post.important_message') }} ({{ unread_posts_count }})</div>
          <v-spacer></v-spacer>
          <v-btn  @click="show = false; $router.push({ name: 'IB' })">zum Infoboard</v-btn>
          <v-btn text icon color="grey lighten-5" @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-2">
          <v-card class="ma-2" v-for="current_post in unread_posts" :key="current_post.id">
            
              <v-card-text>
                <span class="font-weight-bold">{{ current_post.shortText }}</span>
                <span class="float-right">{{ current_post.created_at }}</span>
              
                <v-divider />
                <div class="my-2"> {{ current_post.text }}</div>
                            
                <div> {{ $t('infoboard.post.created_by') }}: {{ current_post.created_by }} </div>
              </v-card-text> 
              
              <v-card-actions>
                <v-spacer />
                <v-btn @click="mark_as_read(current_post.id)" class="rounded-0" color="secondary" dark depressed>
                  {{ $t("infoboard.post.mark_as_read") }}
                </v-btn>
              </v-card-actions>
            
          </v-card>
        </v-card-text>
      </v-card>
   
  </v-dialog>
</template>
<script>
import { csm_util } from "mixins/csm_util.js";

export default {
  mixins: [csm_util],
  data: () => ({
    unread_posts: [],
    unread_posts_count: 0,
    show: false,
    current_post: {},
    categories: [],
    page: 1,
  }),

  mounted() {
    this.get_important_unread_posts()
    // this.$cable.subscribe({
    //   channel: "ImportantPostsChannel",
    // });
  },

  watch: {
    unread_posts: function () {
      if (this.unread_posts && this.unread_posts.length > 0) {
        this.show = true
        
      }
    },

    
  },

  methods: {
    get_important_unread_posts() {
      this.$http({
        method: "get",
        url: `${this.$root.app_url}/infoboard/posts/important_unread`,
      })
        .then((response) => {
          this.unread_posts = response.data.data
          this.unread_posts_count = response.data.total_records
        })
        .catch((response) => { })
        .finally(() => { });
    },

    mark_as_read(id) {
      let self = this;
      this.$http({
        method: "post",
        url: `${this.$root.app_url}/infoboard/posts/mark_as_read`,
        data: {
          post_id: id
        }
      })
        .then((response) => {
          
          self.unread_posts.forEach(function (value, i) {
            if(value.id == id) {
              self.unread_posts.splice(i, 1);
              self.unread_posts_count = self.unread_posts_count - 1
              
            }
            
          })
          if (self.unread_posts_count == 0) {
            self.show = false
          }
        })
        
        .catch((response) => { })
        .finally(() => { 
          
        });
    },

    
  },

  channels: {
    ImportantPostsChannel: {
      connected() { },
      received(data) {
        if (data.message == 'new_important_post') {
          // TODO: push entries via broadcast instead of fetching
          // to prevent different behavior?
          this.get_important_unread_posts();
        }
      }
    },
  },
}
</script>


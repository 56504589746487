<template>
  <v-card color="warning" class="text-center rounded-0" dark>
    <v-card-text class="text-body-1 font-weight-medium">
      <v-icon class="mr-1">mdi-alert-box</v-icon>{{ $t("maintenance.warning") }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>
import Home from "components/home.vue";
import IFrame from "components/iframe.vue";
import Login from "components/login.vue";
import Infoboard from "components/infoboard/main.vue"
import MobileAppsMenu from "components/mobile_apps_menu.vue"

export const navigation_menu_builder = {
  data: () => ({
    main_menu: {
      by_group: [],
    },
    admin_menu: {
      by_group: [],
    },
    routes: []
  }),

  created() {
    this.initialize_routes()
    if (this.user.is_logged_in) {
      this.build_main_menu_by_group()
      this.build_admin_menu_by_group();
      this.setup_routes(this.main_menu_data)
      this.setup_routes(this.admin_menu_data)

      this.add_catch_all_route()
    }
  },

  methods: {
    build_main_menu_by_group: function () {
      let menu_by_group = []
      this.get_menu_group_names(this.main_menu_data).forEach((menu_group_name) => {
        menu_by_group.push(this.get_menu_group_by_name(this.main_menu_data, menu_group_name))
      })

      menu_by_group = menu_by_group.filter((a) => {
        return Object.keys(a).length != 0
      })

      menu_by_group = menu_by_group.sort(function (menu_entry_a, menu_entry_b) {
        return (menu_entry_a.sorting_priority - menu_entry_b.sorting_priority)
      })

      this.main_menu.by_group = menu_by_group
    },

    build_admin_menu_by_group: function () {
      let menu_by_group = []
      this.get_menu_group_names(this.admin_menu_data).forEach((menu_group_name) => {
        menu_by_group.push(this.get_menu_group_by_name(this.admin_menu_data, menu_group_name))
      })

      menu_by_group = menu_by_group.filter((a) => {
        return Object.keys(a).length != 0
      })

      this.admin_menu.by_group = menu_by_group
    },

    get_menu_group_names(menu_data) {
      let menu_group_names = []
      menu_data.filter((menu_entry) => {
        menu_group_names.push(menu_entry.group.name)
      })
      return [...new Set(menu_group_names)]
    },

    get_menu_group_by_name(menu_data, group_name) {
      let entries = menu_data.filter((menu_entry) => {
        return menu_entry.group.name == group_name;
      });

      entries.sort(function (a, b) {
        return a.sorting_priority - b.sorting_priority;
      });

      let icon = "";
      let sorting_priority = undefined;
      if (entries[0]) {
        icon = entries[0].group.icon;
        sorting_priority = entries[0].group.sorting_priority
      }

      let menu_by_group = {}
      if (entries.length != 0) {
        menu_by_group = {
          name: group_name,
          icon: icon,
          sorting_priority: sorting_priority,
          entries: entries,
        }
      }

      return menu_by_group;
    },


    initialize_routes() {
      let root_url = this.rails_relative_url_root
      self = this
      let routes = []
      let home = {
        path: `${root_url}/home`,
        component: Home,
        name: "home",
        label: "Module",
        beforeEnter(to, from, next) {
          if (!self.$root.user.is_logged_in) {
            next({ name: 'login' });
          } else {
            next();
          }
        },
      }
      let redirect_base_path_to_home = { path: `${root_url}/`, redirect: { name: "home" } }
      let login = {
        path: `${root_url}/login`,
        component: Login,
        name: "login",
        props: () => ({ debug: false }),
        beforeEnter(to, from, next) {
          if (self.$root.user.is_logged_in) {
            next({ name: 'home' });
          } else {
            next();
          }
        },
        label: "Login",
      }
      let mobile_apps = {
        path: `${root_url}/mobile`,
        component: MobileAppsMenu,
        name: "mobile_apps_menu",
        label: "Mobile Apps",
      }
      let infoboard = {
        path: `${root_url}/infoboard`,
        component: Infoboard,
        name: "IB",
      }
      let infos = {
        path: `${root_url}/infos`,
        name: "infos",
        beforeEnter() {
          location.href = `${root_url}/infos`;
        },
      }
      let logout = {
        path: `${root_url}/logout`,
        name: "logout",
        beforeEnter() {
          location.href = `${root_url}/logout`;
        },
      }

      routes.push(home, login, redirect_base_path_to_home, mobile_apps, infos, logout)

      if (this.$root.user.is_logged_in && this.$root.config.infoboard.enabled && this.$root.permissions.includes('IB')) {
        routes.push(infoboard)
      }

      this.$router.addRoutes(routes)
    },

    setup_routes(menu_data) {
      let root_url = this.rails_relative_url_root
      let routes = []
      menu_data.forEach(function (menu_entry) {
        if (menu_entry.user_has_permission) {
          let url_search_query = location.search
          if (menu_entry.url.includes('?')){
            url_search_query = `&${url_search_query.substring(1)}`
          }
          routes.push({
            path: `${root_url}/${menu_entry.name_short}`,
            component: IFrame,
            name: menu_entry.name_short,
            beforeEnter: (to, from, next) => {
              if (menu_entry.fullscreen) {
                location.href = menu_entry.url
              } else {
                next()
              }
            },
            props: () => ({ url: `${menu_entry.url}${url_search_query}` }),
            label: menu_entry.name_short
          })
        }
      })

      this.$router.addRoutes(routes)
    },

    add_catch_all_route(){
      // !IMPORTANT! 
      // catch_all must be added last, since wildcard-path matches everything
      // if the page is manually refreshed the first route matching the path will be applied
      let catch_all = { path: '/:pathMatch(.*)*', redirect: { name: "home" } }

      this.$router.addRoutes([catch_all])
    }
  }
}
<template>
  <v-card color="grey lighten-5">
    <v-card-title> {{ $t("infoboard.post.read_by") }}: </v-card-title>
    <v-card-text v-for="(read_details, index) in post_read_list" :key="index">
      <v-row>
        <v-col sm="6">
          {{ read_details.user.name }}
        </v-col>
        <v-col sm="6">
          {{ format_date_time(read_details.read) }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { csm_util } from "mixins/csm_util";

export default {
  mixins: [csm_util],
  props: {
    post_id: Number,
    refresh: false,
  },
  data: () => ({
    post_read_list: [],
  }),

  created() {
    this.get_post_read_list();
  },

  watch: {
    post_id : function () {
      this.get_post_read_list();
    },

    refresh: function (new_value) {
      if (new_value) {
        this.get_post_read_list()
      }
    },
  },

  methods: {
    get_post_read_list() {
      this.$http({
        method: "get",
        url: `${this.$root.app_url}/infoboard/posts/${this.post_id}/read`,
      })
        .then((response) => {
          this.post_read_list = response.data;
          this.$emit('refreshed')
        })
        .catch((response) => { })
        .finally(() => { });
    },
  },
};
</script>
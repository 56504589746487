<template>
  <div id="main_menu" :class='`${horizontal_margin} background-image`'
    :style="`background-image: url(${$root.app_url}/images/csmed_V8.png)`">
    <v-row>
      <v-spacer />
      <v-card v-if="$root.main_menu.by_group.length == 0 && !$root.main_menu.request_in_progress">
        <v-card-title>{{ $t("menu.no_entries") }}</v-card-title>
      </v-card>
      <v-col class="px-4" cols="12" sm="6" md="6" lg="3" xl="3"
        v-for="(menu_group, menu_group_index) in $root.main_menu.by_group" :key="menu_group_index">
        <menu-column :menu_group="menu_group" />
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>
<script>
import MenuColumn from "components/sidebar/partials/main_menu/column.vue";

export default {
  components: { MenuColumn },
  computed: {
    horizontal_margin() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm': return ''
        case 'md': return 'mx-5'
        case 'lg': return 'mx-10'
        case 'xl': return 'mx-15'
      }
    }
  }
};
</script>
<style scoped>
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 700px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
</style>
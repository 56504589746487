<template>
  <v-snackbar v-model="show_message" color="green" top>
    <v-alert class="ma-0" type="success">{{ message }}</v-alert>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    message: '',
    show: false,
  },

  computed: {
    show_message: {
      get() {
        return this.show
      },
      set (value){
        this.$emit('close')
      }
    }
  },
}
</script>
export const csm_util = {
  methods: {
    format_date(date) {
      date = new Date(date);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },

    format_date_time(date) {
      let dateString = this.format_date(date);

      date = new Date(date);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);

      return `${dateString} ${hours}:${minutes}`;
    },

    shorten_string_to_length(string, length, add_dots = true) {
      let shortened_string = string
      if (string.length > length) {
        shortened_string = string.substring(0, length)
        if (add_dots) {
          shortened_string = `${shortened_string}...`
        }
      }

      return shortened_string
    }
  }
}
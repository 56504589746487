<template>
  <v-card style="background-color: transparent">
    <v-card-title class="justify-center primary font-italic white--text">
      <v-spacer />
      <v-icon left class="white--text">{{ menu_group.icon }}</v-icon>
      {{ menu_group.name }}
      <v-spacer />
      <v-icon class="white--text" @click="collapsed = !collapsed">
        {{ collapsed? 'mdi-arrow-collapse': 'mdi-arrow-expand' }}
      </v-icon>
    </v-card-title>
    <v-list :class="collapsed ? 'py-0' : ''" style="background-color: rgba(255,255,255, 0.90)">
      <div v-for="(module, index) in menu_group.entries" :key="index">
        <v-expand-transition>
          <div v-show="(index < 7 && !collapsed) || show_full_column && !collapsed">
            <v-list-item link :to="{ name: module.name_short }">
              <v-list-item-icon class="mr-4 align-self-center">
                <v-icon color="primary">mdi-arrow-right-bold-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ module.name_short }}
                </v-list-item-title>
                <v-list-item-subtitle class="overflow-auto" style="white-space: unset;">
                  {{ module.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-expand-transition>
      </div>
    </v-list>
    <v-expand-transition>
      <v-btn color="white" depressed x-small v-show="menu_group.entries.length > 7 && !collapsed" block
        @click="show_full_column = !show_full_column" style="border-top-left-radius: 0; border-top-right-radius: 0;">
        <v-icon small>{{ show_full_column? 'mdi-chevron-double-up': 'mdi-chevron-double-down' }}</v-icon>
      </v-btn>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    menu_group: {}
  },
  data: () => ({
    show_full_column: false,
    collapsed: false,
  }),
  methods: {

  }
}
</script>

<template>
  <v-card>
    <v-card-title class="px-3 py-0 justify-center blue lighten-1">
      <h4 class="white--text">
        {{ $t("session_expired.headline") }}
      </h4>
    </v-card-title>
    <v-card-text class="text-center">
      {{ $t("session_expired.automatic_redirect") }}: {{ timer }}
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn @click="go_to_login_with_reload()">
        {{ $t("to_login") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      timer: 10
    }
  },

  watch: {
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timer--;
          }, 1000);
        } else {
          this.go_to_login_with_reload()
        }
      },
      immediate: true
    }
  },

  methods: {

    go_to_login_with_reload() {
      this.$router.push("login");
      this.$router.go();
    },
  },
};
</script>
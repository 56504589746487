<template>
  <v-list-group v-model="is_open" color="grey lighten-1">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon>{{ menu_group.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ menu_group.name }}</v-list-item-title>
      </v-list-item-content>
    </template>
    <div v-for="(entry, index) in menu_group.entries" :key="index">
      <v-list-item link :to="{ name: entry.name_short }" v-if="entry.user_has_permission">
        <v-list-item-icon>
          <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ entry.name_short }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list-group>
</template>
<script>
export default {
  props: {
    menu_group: Object,
    close_after_route_change: Boolean
  },

  data() {
    return {
      is_open: false
    }
  },

  created() {
    let self = this
    this.$eventbus.$on('iframe_after_load', function () {
      if (self.close_after_route_change)
        self.is_open = false
    })
  }
}
</script>
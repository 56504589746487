<template>
  <div>
    <v-card>
      <v-card-title><h1>zu schmal</h1></v-card-title>
      <v-card-text>
        <v-alert type="error">Die von Ihnen verwendete Geräte/Fenster-Breite wird von dieser App nicht unterstützt. Bitte verwenden Sie ein breiteres Gerät/Fenster.<br/><br/>Evtl. können Sie Ihr mobiles Gerät quer drehen.</v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'TooSmall',
    props: {
      debug: Boolean,
    },
    data: () => ({
    }),
  }
</script>
<template>
  <v-navigation-drawer disable-resize-watcher disable-route-watcher :value="is_open" right app clipped dark
    color="secondary">
    <v-tabs v-if="$root.admin_menu.by_group.length > 0">
      <v-tab>
        <v-icon>mdi-home</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>mdi-cogs</v-icon>
      </v-tab>
      <v-tab-item>
        <v-card flat tile color="secondary" dark>
          <v-card-title v-if="$root.admin_menu.by_group.length == 0">
            {{ $t('menu.no_entries') }}
          </v-card-title>
          <div v-for="(menu_group, index) in $root.admin_menu.by_group" :key="index">
            <v-card-title>
              <h5>{{ menu_group.name }}</h5>
            </v-card-title>
            <v-card-text class="px-0">
              <v-list dense>
                <admin-menu-list-item v-for="(entry, index) in menu_group.entries" :key="index" :module="entry"/>
              </v-list>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <admin-menu-settings/>
      </v-tab-item>
    </v-tabs>
    <admin-menu-settings v-else/>
  </v-navigation-drawer>
</template>

<script>
import AdminMenuListItem from "components/sidebar/partials/admin_menu/list_item";
import AdminMenuSettings from "components/sidebar/partials/admin_menu/settings"

export default {
  components: { AdminMenuListItem, AdminMenuSettings },
  props: {
    is_open: Boolean,
  },
};
</script>
<template>
  <div>
    <v-menu bottom offset-y nudge-bottom="4" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="mx-3">
          <span xs>{{ total_entries }}</span>
          <v-icon>mdi-email</v-icon>
        </v-btn>
      </template>

      <v-list class="pa-0"  width="400">
        <div>
          <v-list-item style="min-height: 30px">{{ $t('infoboard.unseen_messages') }}: {{ total_entries }} </v-list-item>
          <v-divider />
        </div>
        <div v-for="(post, index) in posts" :key="index">
          <v-list-item @click="select_post(post)">
            <v-list-item-content>
              <v-list-item-subtitle xs>
                <span>{{ cut_username_characters_length(post.created_by) }}</span>
                <span class="float-right">{{ post.created_at }}</span>
              </v-list-item-subtitle>
              <v-list-item-title><v-icon>mdi-email</v-icon> {{ post.shortText }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </div>
        <v-list-item style="min-height: 30px" >
          <v-list-item-content>
            <v-btn @click="go_to_infoboard()">{{ $t('infoboard.go_to_infoboard') }}</v-btn>
            
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="show_selected_post" persistent :max-width="this.$root.global_modal_width">
      <post :post_id="selected_post.id" @close="show_selected_post = false; get_unseen_posts()" />
    </v-dialog>
  </div>
</template>
<script>
import Post from "components/infoboard/post/main"
import { csm_util } from "../mixins/csm_util";

export default {
  components: { Post },
  mixins: [csm_util],
  data: () => ({
    selected_post: {},
    show_selected_post: false,
    posts: [],
    total_entries: 0,
    request_in_progress: false,
  }),

  mounted() {
    this.get_unseen_posts()
    this.$cable.subscribe({
      channel: "PostsChannel",
    });
    this.$eventbus.$on('infoboard_subscribed_interests_changed', () => {
      this.get_unseen_posts();
    })
    this.$eventbus.$on('infoboard_post_marked_as_seen', () => {
      this.get_unseen_posts();
    })
  },

  methods: {
    select_post(post) {
      this.selected_post = post
      this.show_selected_post = true
    },

    get_unseen_posts() {
      this.request_in_progress = true;
      let url = new URL(this.$root.app_url);
      url.pathname = `${url.pathname}/infoboard/posts/by_interests`;
      this.$http({
        method: "get",
        url: url.href,
      })
        .then((response) => {
          this.total_entries = response.data.total_records
          this.posts = response.data.data
          
        })
        .catch((response) => { })
        .finally(() => {
          this.request_in_progress = false;
        });

    },

    go_to_infoboard() {
      this.$router.push({ name: 'IB' })
    },

    cut_username_characters_length(username) {
      return this.shorten_string_to_length(username, 30)
    }
  },

  channels: {
    PostsChannel: {
      connected() {},
      received(data) {
        if (data.message == 'new_post' || data.message == 'post_deleted') {
          this.get_unseen_posts();
        }
      }
    },
  },
}

</script>
<template>
  <v-card flat tile color="secondary" dark>
    <v-card-title>{{ $t('menu.infoboard_interests') }}</v-card-title>
    <div class="text-center mt-4" v-if="initial_data_fetch_in_progress">
      <v-progress-circular :size="100" :width="7" color="blue darken-3" indeterminate />
    </div>
    <v-treeview
      v-model="selected_categories"
      hoverable
      selectable
      open-all
      multiple-active
      :selection-type="'independent'"
      :items="categories"
      item-text="name"
      item-children="subcategories"
      :open="open_categories"
    />
  </v-card>
</template>

<script>
export default {
  data: () => ({
    categories: [],
    selected_categories: [],
    open_categories: [],
    initial_data_fetch_in_progress: false,
  }),


  mounted() {
    this.get_categories()
  },

  watch: {
    selected_categories: function (new_category_ids, old_category_ids) {
      if (this.initial_data_fetch_in_progress) {
        // if we assign a value to 'selected_categories' we have to skip the watcher, otherwise the callbacks get triggered
        // after fetching the current status from the database
        return;
      }
      if (new_category_ids.length > old_category_ids.length) {
        let category_id = this.get_array_diff(new_category_ids, old_category_ids)
        // since we are watching the value the difference will always contain only one value
        this.subscribe_interest_to_category(category_id[0])
      } else {
        let category_id = this.get_array_diff(old_category_ids, new_category_ids)
        // since we are watching the value the difference will always contain only one value
        this.unsubscribe_interest_to_category(category_id[0])
      }
    },
    
    categories: function() {
      this.open_categories = this.get_open_categories(this.categories)
    }
  },

  methods: {
    subscribe_interest_to_category(category_id) {
      this.request_in_progress = true;
      this.$http({
        method: "post",
        url: `${this.$root.app_url}/menu/admin/interests/subscribe`,
        data: { infoboard_category_id: category_id },
      })
        .then((response) => {
          this.$eventbus.$emit('infoboard_subscribed_interests_changed')
          this.$eventbus.$emit('infoboard_interest_subscribed', {message: this.$t('menu.interest_subscription.subscribed')})
        })
        .catch((response) => { })
        .finally(() => {
          this.request_in_progress = false;
        });
    },

    unsubscribe_interest_to_category(category_id) {
      this.request_in_progress = true;
      this.$http({
        method: "delete",
        url: `${this.$root.app_url}/menu/admin/interests/subscribe`,
        data: { infoboard_category_id: category_id },
      })
        .then((response) => {
          this.$eventbus.$emit('infoboard_subscribed_interests_changed')
          this.$eventbus.$emit('infoboard_interest_unsubscribed', {message: this.$t('menu.interest_subscription.unsubscribed')})
        })
        .catch((response) => { })
        .finally(() => {
          this.request_in_progress = false;
        });
    },

    get_array_diff(array, entries_to_be_removed) {
      entries_to_be_removed = new Set(entries_to_be_removed)
      let difference = array.filter(entry => !entries_to_be_removed.has(entry))
      return difference
    },

    get_categories() {
      this.initial_data_fetch_in_progress = true
      this.$http({
        method: "get",
        url: `${this.$root.app_url}/infoboard/categories`,
      })
        .then((response) => {
          this.categories = response.data.categories;
          this.selected_categories = response.data.subscribed_categories
        })
        .catch((response) => { })
        .finally(() => {
          this.initial_data_fetch_in_progress = false
        });
    },

    get_open_categories(categories) {
      let open_categories = []
      categories.forEach((category) => {
        if (category.subcategories.length > 0) {
          open_categories.push(category.id)
          open_categories.push(this.get_open_categories(category.subcategories))
        }
      })
      return open_categories.flat()
    },
  },
}
</script>
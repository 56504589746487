<template>
  <v-container>
    <h2>{{ $t("infoboard.comment.headline") }}</h2>
    <div v-for="(comment, index) in comments" :key="index">
      <v-card color="grey lighten-5" class="ma-2 px-2">
        <v-row>
          <v-col md="11">
           <span class="font-weight-bold">{{ comment.created_by }}</span> <span class="float-right">{{ format_date_time(comment.created_at) }}</span>
            <p style="white-space: break-spaces;"> {{ comment.content }} </p>
          </v-col>
          <v-col md="1">
            <v-btn text icon class="pa-0 ma-0" v-if="comment.can_be_deleted" @click="delete_comment(comment.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <comment-create-form :post_id="post_id" @submit="get_comments()" />
    <confirm ref="confirm" />
    <warning-flash
      :message="$t('infoboard.comment.deleted')"
      :show="flash_message.show_deleted"
      @close="flash_message.show_deleted = false"
    />
  </v-container>
</template>

<script>
import CommentCreateForm from "components/infoboard/comment/create_form.vue";
import Confirm from "components/confirm.vue";
import WarningFlash from "components/snackbar/warning.vue"
import { csm_util } from "mixins/csm_util";

export default {
  mixins: [csm_util],
  components: { CommentCreateForm, Confirm, WarningFlash },
  props: {
    post_id: Number,
  },
  data: () => ({
    comments: [],
    flash_message: {
      show_deleted: false,
    }
  }),

  created() {
    this.get_comments();
  },

  watch: {
    post: function () {
      this.get_comments();
    },
  },

  methods: {
    get_comments() {
      this.$http({
        method: "get",
        url: `${this.$root.app_url}/infoboard/comments/post/${this.post_id}`,
      })
        .then((response) => {
          this.comments = response.data;
        })
        .catch((response) => { })
        .finally(() => { });
    },

    delete_comment(comment_id) {
      this.$refs.confirm
        .open("Kommentar löschen", "Wollen Sie Ihren Kommentar wirklich löschen?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.delete_call(comment_id);
          }
        });
    },

    delete_call(comment_id) {
      this.$http({
        method: "delete",
        url: `${this.$root.app_url}/infoboard/comments/${comment_id}`,
      })
        .then((response) => {
          this.flash_message.show_deleted = true
          this.get_comments();
        })
        .catch((response) => { })
        .finally(() => { });
    },
  },
};
</script>
<template>
  <v-expansion-panels flat tile color="secondary rounded-0" dark>
    <v-expansion-panel>
      <v-expansion-panel-header color="secondary">Rechte</v-expansion-panel-header>
      <v-expansion-panel-content color="secondary">
        <v-list>
          <v-list-item v-for="(permission_name, index) in permission_list()" :key="index">
            <v-list-item-title>{{ permission_name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  methods: {
    permission_list() {
      let permission_list = this.$root.permissions.filter((permission_name) => {
        return permission_name.startsWith(this.$route.name)
      })
      if (permission_list.length == 0){
        permission_list = this.$root.permissions
      } 
      return permission_list
    }
  }
}
</script>
<template>
  <div>
    <v-card>
      <v-card-text>
        <a href="/csm/modules/ADMmobile" target="_blank">
          <v-row class="mx-0">
            <v-col md="1">
              <div color="cyan">
                <v-icon size="125">mdi-earth</v-icon>
              </div>
            </v-col>
            <v-col md="11">
              <v-card-title class="pb-1">ADMmobile Mobiles </v-card-title>
              <v-card-title class="pt-0">Außendienst Management</v-card-title>
            </v-col>
          </v-row>
        </a>
        <v-card-subtitle class="text-caption pt-0"
          >Alternativ besteht die Möglichkeit, das ADMmobile als App auf dem
          Endgerät zu installieren. Bitte beachten Sie hierzu die Hinweise am
          Bildschirmrand, nachdem Sie den vorstehenden Link aufgerufen haben
          oder wählen Sie im Browser-Menü die Option 'zum Startbildschirm
          hinzufügen'. Bei installierter App kann das Modul direkt aufgerufen
          werden.</v-card-subtitle
        >
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {};
</script>
import { de } from "vuetify/src/locale";

export default {
  ...de,
  button: {
    save: "Speichern",
  },
  session_expired: {
    headline: 'Ihre aktuelle Session ist abgelaufen!',
    automatic_redirect: 'Automatische Weiterleitung'
  },
  to_login: 'Zum Login',
  maintenance: {
    warning: 'Achtung: Das System wird in kürze in den Wartungsmodus versetzt. Bitte speichern Sie Ihren aktuellen Vorgang ab und loggen Sie sich aus um Datenverlust zu vermeiden.'
  },
  password: {
    change_label: "Passwort ändern",
    old: "Altes Passwort",
    new: "Neues Passwort",
    new_check: "Neues Passwort bestätigen",
    error: {
      headline: 'Fehler beim Ändern des Passworts',
      old_password_mismatch: 'Eingabe stimmt nicht mit Ihrem alten Passwort überein.'
    },
    changed: "Passwort wurde geändert.",
  },
  user: {
    label: "Benutzer",
    login: "Login",
    first_and_last_name: "Vor-/Nachname",
    email: "E-Mail",
    last_login: 'Letzter Login'
  },
  permissions: {
    label: "Rechte",
    insufficient: "Zugriffsrecht nicht vorhanden.",
  },
  menu: {
    settings: "Einstellungen",
    infoboard_interests: "InfoBoard Interessen",
    user_information: "Benutzer Informationen",
    user_permissions: "Benutzer Rechte",
    interest_subscription: {
      subscribed: 'Interesse angemeldet',
      unsubscribed: 'Interesse abgemeldet',
    },
    no_entries: 'Keine Einträge vorhanden.'
  },
  infoboard: {
    new_messages: 'Neue Nachrichten',
    unseen_messages: 'Ungelesene Nachrichten',
    go_to_infoboard: 'Zum Infoboard',
    post: {
      form: {
        create_label: "Infoboard Eintrag erstellen",
        edit_label: "Infoboard Eintrag editieren",
        headline: "Überschrift",
        important: {
          label: "Wichtige Nachricht",
          true: "Ja",
          false: "Nein",
        },
        valid_from: "Gültig von",
        valid_till: "Gültig bis",
        submit: "Speichern",
      },
      important_message: 'Wichtige Nachrichten',
      created_by: "Von",
      created_at: "Erstellt am",
      category: "Kategorie",
      read_by: "Gelesen von",
      mark_as_read: "Als gelesen markieren",
      mark_all_as_read: "Alle Nachrichten als gelesen markieren",
      filter: {
        label: "Filter",
        no_filter: "Alle",
        unread: "Ungelesen",
        read: "Gelesen",
      },
      created_success: "Eintrag erstellt",
      deleted: "Eintrag gelöscht",
    },
    pagination: {
      entries_per_page: "Zeilen/Seite",
    },
    comment: {
      headline: "Kommentare",
      form: {
        submit: "Kommentieren",
      },
      create: "Kommentar erstellen",
      created_success: 'Kommentar wurde erstellt.',
      deleted: 'Kommentar wurde gelöscht.'
    },
    search: {
      label: 'Suchen',
      info: 'in Inhalt und Titeln von Infoboardeinträgen.',
      no_results: 'Keine Einträge gefunden.'
    },
    permission_missing: 'Das Recht "IB" fehlt für das Infoboard.'
  },
};

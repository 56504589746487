<template>
  <div>
    <img :class="rotation_class" :src="current_image" :alt="alt_text" @animationend="swap_rotation_cycle" />
  </div>
</template>

<script>
export default {
  name: "RotatingImage",
  props: {
    images: {
    },
    alt_text: "",
    should_rotate: true,
  },
  data: () => ({
    current_image: "",
    rotation: 1,
  }),

  computed: {
    rotation_class: {
      get(){
        let class_string = ''
        if(this.images.should_rotate){
          class_string = `rotate rotation_${this.rotation}`
        }

        return class_string
      }
    }
  },

  created() {
    if (this.images.should_rotate) {
      this.current_image = `${this.$root.app_url}/${this.images.first}`;
    } else {
      this.current_image = `${this.$root.app_url}/${this.images.second}`;
    }
  },

  methods: {
    swap_rotation_cycle() {
      if (this.rotation % 2 == 0) {
        this.rotation = 1;
        this.current_image = `${this.$root.app_url}/${this.images.first}`;
      } else {
        this.rotation = 2;
        this.current_image = `${this.$root.app_url}/${this.images.second}`;
      }
    },
  },
};
</script>

<style scoped>
@keyframes rotating_first_half {
  0% {
    transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
  }

  100% {
    transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg);
  }
}

@keyframes rotating_second_half {
  0% {
    transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg);
  }

  100% {
    transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
  }
}

.rotate.rotation_1 {
  -webkit-animation: rotating_first_half 10s linear;
  -moz-animation: rotating_first_half 10s linear;
  -ms-animation: rotating_first_half 10s linear;
  -o-animation: rotating_first_half 10s linear;
  animation: rotating_first_half 10s linear;
}

.rotate.rotation_2 {
  -webkit-animation: rotating_second_half 10s linear;
  -moz-animation: rotating_second_half 10s linear;
  -ms-animation: rotating_second_half 10s linear;
  -o-animation: rotating_second_half 10s linear;
  animation: rotating_second_half 10s linear;
}
</style>
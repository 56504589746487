<template>
  <v-card>
    <v-card-title class="py-2" style="background-color: #3c8dbc">
      <h3 class="white--text"> {{ post.headline }}</h3>
      <v-spacer></v-spacer>
      
      <v-btn text icon color="grey lighten-5" v-if="post.can_be_edited" @click="edit_post()"  >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn text icon color="red lighten-1" v-if="post.can_be_deleted" @click="delete_post()" >
        <v-icon>mdi-delete-empty</v-icon>
      </v-btn>
      <v-btn text icon color="grey lighten-5" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row class="mx-0">
        <v-col md="8">
          <h2><v-icon color="red" v-if="post.is_important">mdi-alert-box</v-icon> {{ post.headline }}</h2>
          <v-row class="mx-0">
            <v-col md="6">
                <div>
                  <span class="font-weight-bold">Von:</span>
                  {{ post.created_by }}
                </div>
                <div>
                  <span class="font-weight-bold">{{ $t("infoboard.post.form.valid_from") }}:</span>
                  {{ format_date(post.valid_from) }}
                </div> 
               
                <div>
                  <span class="font-weight-bold">{{ $t("infoboard.post.form.valid_till") }}:</span>
                  {{ format_date(post.valid_till) }}
                </div> 
              
            </v-col>
            <v-col md="6">
              <div>
                <span class="font-weight-bold">Datum:</span>
                {{ format_date_time(post.created_at) }}
              </div> 
              <div>
                <span class="font-weight-bold">{{ $t("infoboard.post.category") }}:</span>
                {{ post.category_path }}
              </div>
              
             
            </v-col>
          </v-row>
          <v-divider />
          <v-card-text > {{ post.text }} </v-card-text>
          <v-divider />

          <v-row>
            <csm-file-input
              :showUpload="false"
              disabled
              _v-model="post.files"
              :reference="post.id"
              :url="`${$root.baseUrl.replace(/\/$/,'')}${$route.path}/posts`"

            ></csm-file-input>
          </v-row>
          <p class="ma-2 text-end">
            <v-btn v-if="!post.read" @click="mark_as_read()" class="rounded-0" color="secondary" dark depressed>
              {{
                  $t("infoboard.post.mark_as_read")
              }}
            </v-btn>
          </p>
          
          <comments :post_id="post_id" />
        </v-col>
        <v-col md="4">
          <read-list :post_id="post_id" :refresh="refresh_read_list" @refreshed="refresh_read_list = false" />
        </v-col>
      </v-row>


      <v-dialog v-model="show_edit_form" :max-width="this.$root.global_modal_width">
        <post-form :post="post" :key="rerender_post_form_component_key" :categories="categories"
          @close="show_edit_form = false; rerender_post_form_component_key += 1" @edited="get_post(); $emit('edited')" />
      </v-dialog>
    </v-card-text>
    
    <confirm ref="confirm" />

    
  </v-card>
    
</template>  

<script>
import PostForm from 'components/infoboard/post/_form.vue'
import ReadList from "components/infoboard/post/read_list.vue";
import { csm_util } from "mixins/csm_util";
import Confirm from "components/confirm.vue";
import Comments from "components/infoboard/comment/main.vue";
import CsmFileInput from 'csmed-elements/csm-file-input.vue'

export default {
  components: { PostForm, Comments, ReadList, Confirm, CsmFileInput },
  mixins: [csm_util],
  props: {
    categories: Array,
    post_id: Number,
  },
  data: () => ({
    post: {
      category: {}
    },
    request_in_progress: false,
    snackbar: {
      show_created: false
    },
    show_edit_form: false,
    refresh_read_list: false,

    // assigned via :key, incrementing forces vue to rerender the component
    rerender_post_form_component_key: 0,
  }),

  created() {
    this.get_post();
  },

  watch: {
    post_id: function () {
      this.get_post();
    },
  },

  methods: {
    edit_post() {
      this.show_edit_form = true
    },

    delete_post() {
      this.$refs.confirm
        .open("Nachricht löschen", "Wollen Sie die Nachricht wirklich löschen?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.delete_call();
          }
        });
    },

    delete_call() {
      this.$http({
        method: "delete",
        url: `${this.$root.app_url}/infoboard/posts/${this.post_id}`,
      })
        .then((response) => {
          this.$emit("deleted");
          this.$emit("close");
        })
        .catch((response) => { })
        .finally(() => { });
    },

    get_post() {
      this.request_in_progress = true
      this.$http({
        method: "get",
        url: `${this.$root.app_url}/infoboard/posts/${this.post_id}`,
      })
        .then((response) => {
          this.post = response.data
          this.$eventbus.$emit('infoboard_post_marked_as_seen')
        })
        .catch((response) => { })
        .finally(() => {
          this.request_in_progress = false
        });
    },

    mark_as_read() {
      this.$http({
        method: "post",
        url: `${this.$root.app_url}/infoboard/posts/mark_as_read`,
        data: {
          post_id: this.post.id
        }
      })
        .then((response) => {
          this.post = response.data
          this.refresh_read_list = true
          this.$emit("marked_as_read");
        })
        .catch((response) => { })
        .finally(() => { });
    },
  },
};
</script>
<template>
  <div>
    <v-card-title>{{ $t('infoboard.comment.create') }}</v-card-title>
    <v-form>
      <v-textarea outlined v-model="form.content">
      </v-textarea>
      <v-btn @click="submit">{{ $t("infoboard.comment.form.submit") }}</v-btn>
    </v-form>
    <success-flash
      :message="$t('infoboard.comment.created_success')"
      :show="flash_message.show_created"
      @close="flash_message.show_created = false"
    />
  </div>
</template>
<script>
import SuccessFlash from "components/snackbar/success.vue"

export default {
  components: { SuccessFlash },
  props: {
    post_id: Number,
  },
  data: () => ({
    form: {
      content: "",
    },
    flash_message: {
      show_created: false,
    }
  }),
  methods: {
    submit() {
      this.$http({
        url: `${this.$root.app_url}/infoboard/comments/post/${this.post_id}`,
        method: "post",
        data: this.form,
      })
        .then((response) => {
          this.flash_message.show_created = true
          this.$emit('submit')
          this.form.content = '';
        })
        .catch((error) => {
          //TODO: error handling
        })
        .finally(() => { });
    },
  },
};
</script>
import Vue from 'vue/dist/vue.esm'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',  
  // base: '/csm/FRM',
  routes: [
    // keine statischen Routen. Die Routing-Entries werden in main.vue definiert und dynamisch injiziert.
    // Beim Injizieren werden ggf. Pfade angepasst (production, relative root url) und von Rechten abhängig
    // ein- und ausgeblendet.
  ],
});

export default router
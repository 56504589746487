<template>
  <v-dialog v-model="show_session_expired" persistent scrollable max-width="25rem">
    <session-expired />
  </v-dialog>
</template>

<script>
import SessionExpired from "components/session_expired.vue";

export default {
  components: {
    SessionExpired
  },

  data() {
    return {
      session_watchdog_cookie: undefined,
      show_session_expired: false
    }
  },

  mounted() {
    if (this.$root.user.is_logged_in) {
      this.setup_session_watchdog()
    }
  },

  methods: {
    setup_session_watchdog() {
      let watchdog = 'csm_watchdog'
      this.session_watchdog_cookie = this.get_cookie(watchdog)
      let self = this
      window.setInterval(function () {
        if (self.session_watchdog_cookie) {
          if (self.session_watchdog_cookie != self.get_cookie(watchdog)) {
            self.show_session_expired = true
          }
        }
      }, 10000)
    },

    get_cookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return undefined;
    }
  },
}
</script>

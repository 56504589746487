<template>
  <v-container fluid id="home">
    <v-alert v-if="!this.$root.user.is_logged_in" class="mb-4" type="warning">
      Sie müssen angemeldet sein!
      <v-btn :disabled="this.$root.user.is_logged_in" :to="{ name: 'login' }" class="ml-10">Wieder Anmelden</v-btn>
    </v-alert>
    <!-- <v-card color="primary" dark>
      <v-card-title>CSMed Frame - Home</v-card-title>
      <v-card-text>
        Dies ist die Neu-Implementierung des
        <strong>CSMed - Rahmens</strong>s. Implementiert ist dies als sog.
        Single-Page-App, getrieben wird dies durch Ruby-On-Rails und MySQL im
        Backend und Vue.js und Vuetify.js im Frontend.
      </v-card-text>
    </v-card> -->
    <div v-if="this.$root.user.is_logged_in">
      <main-menu :menu="menu" :menu_request_in_progress="menu_request_in_progress" />
    </div>
  </v-container>
</template>

<script>
import MainMenu from 'components/main_menu'
export default {
  name: "Module",
  components: { MainMenu },
  props: {
    debug: Boolean,
    menu: Array,
    menu_request_in_progress: Boolean,
  },
  data: () => ({}),
  created() {
    this.debug && console.log(`component Home created...`);
    this.debug && console.log(`baseUrl: ${this.baseUrl}`);
    this.debug && console.log(this.$route);
  },
  mounted() {
    this.debug && console.log(`component Home mounted...`);
    this.$root.$refs.mainapp.$refs.appBarTitle.innerHTML = "Module";
    this.$root.$refs.mainapp.$refs.footerMiddle.innerHTML = this.$root.config.customer_name
  },
};
</script>

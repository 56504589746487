<template>
  <div>
    <v-app-bar v-if="$route.name != 'login'" ref="appBar" app color="primary" dark clipped-left clipped-right dense>
      <v-btn icon link active-class="no-active" @click="go_to_home">
        <rotating-image-icon height="44" image="CSMedAppLogo192.png" alt_text="CSMed Logo" icon="mdi-home" />
      </v-btn>
      <v-app-bar-nav-icon :disabled="!this.$root.user.is_logged_in" @click.stop="leftDrawer = !leftDrawer">
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-5">
        <span ref="appBarTitle" id="navbar-middle" class="mr-5"></span>
      </v-toolbar-title>
      <v-spacer />
      <unseen-posts v-if="$root.user.permissions.infoboard && $root.config.infoboard.enabled" v-show="this.$root.user.is_logged_in" />
      <v-btn icon v-show="this.$root.user.is_logged_in && this.$root.permissions.includes('RQM')"
        @click="open_rqm_form()">
        <v-icon>mdi-clipboard-edit</v-icon>
      </v-btn>
      <v-btn text v-show="!this.$root.user.is_logged_in" :to="{ name: 'login' }" class="text-none">
        <v-icon class="mr-2">mdi-login</v-icon>Login
      </v-btn>
      <v-btn icon v-show="this.$root.user.is_logged_in" @click="open_documentation()">
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>
      <v-btn icon v-show="this.$root.user.is_logged_in" @click.stop="rightDrawer = !rightDrawer">
        <v-icon class="mr-2">mdi-cogs</v-icon>
      </v-btn>
      <v-menu bottom left offset-y nudge-bottom="10" transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" v-show="$root.user.is_logged_in" class="pa-0 text-none">
            <v-icon>mdi-account-check</v-icon>
            <span v-if="!$vuetify.breakpoint.mobile">{{ $root.user.login_name }}</span>
          </v-btn>
        </template>
        <v-card color="indigo lighten-5" style="min-width: 250px">
          <v-card-title>
            <v-icon class="mr-2">mdi-account-check</v-icon>
            <h4>{{ $root.user.name }}</h4>
          </v-card-title>
          <v-card-subtitle>{{ $t('user.last_login') }}: {{ format_date($root.user.last_login) }}</v-card-subtitle>
          <v-card-actions>
            <v-btn text class="primary text-none" @click="show_password_change_modal = true">Passwort ändern</v-btn>
            <v-spacer />
            <v-btn class="secondary" text :disabled="!this.$root.user.is_logged_in" :to="{ name: 'logout' }">
              <v-icon>mdi-door-open</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <navigation-sidebar-left ref="sidebarLeft" :is_open="leftDrawer" :showInfos="showInfos" @update_drawer="update_left_drawer" />

    <navigation-sidebar-right :is_open="rightDrawer" />

    <v-main class="fill-height" style="padding-right: 0 !important">
      <maintenance-warning v-if="$root.maintenance_warning" />
      <!-- <too-small :class="toSmallClass + ' ma-1'" /> -->

      <!-- hiermit wird die per router ausgewählte Componente hier angezeigt -->
      <transition-group name="fade" mode="out-in">
        <router-view :key="$route.path" :base-url="baseUrl" :root-url="rootUrl" :session-id="sessionId" :start-params="startParams" />
      </transition-group>
      <v-dialog v-model="show_password_change_modal" persistent :max-width="'35rem'">
        <change-password @close="show_password_change_modal = false"
          @password_changed="password_changed_successfully" />
      </v-dialog>
      <success-flash :message="snackbar_message" :show="show_snackbar_success" @close="hide_snackbar_messages()" />

      <warning-flash :message="snackbar_message" :show="show_snackbar_warning" @close="hide_snackbar_messages()" />

      <unread-important-posts v-if="$root.user.is_logged_in && $root.config.infoboard.enabled && $root.user.permissions.infoboard" />
      
      <watch-dog/>
    </v-main>

    <v-footer ref="footer" app color="primary" dark>
      <span>&copy; CSMed GmbH</span>
      <v-spacer />
      <span ref="footerMiddle" id="footer-middle" class="mr-2"></span>
      <v-spacer />
      <span> Version 8.3 </span>
    </v-footer>
  </div>
</template>

<script>
import TooSmall from "components/too-small.vue";
import NavigationSidebarLeft from "components/sidebar/left.vue";
import NavigationSidebarRight from "components/sidebar/right.vue";
import UnseenPosts from "components/unseen_posts.vue";
import UnreadImportantPosts from "components/unread_important_posts.vue";
import ChangePassword from "components/change_password.vue";
import SuccessFlash from "components/snackbar/success.vue";
import WarningFlash from "components/snackbar/warning.vue"
import MaintenanceWarning from "components/maintenance_warning.vue";
import { csm_util } from "mixins/csm_util.js";
import RotatingImageIcon from "components/rotating_image_to_icon.vue"
import WatchDog from "components/watchdog.vue"


export default {
  name: "Main",
  mixins: [csm_util],
  components: {
    TooSmall,
    NavigationSidebarLeft,
    NavigationSidebarRight,
    UnseenPosts,
    UnreadImportantPosts,
    ChangePassword,
    SuccessFlash,
    WarningFlash,
    MaintenanceWarning,
    RotatingImageIcon,
    WatchDog,
  },
  props: {
    debug: Boolean,
    baseUrl: String,
    rootUrl: String,
    sessionId: String,
    railsRelativeUrlRoot: String,
    startParams: undefined,
    locale: String,
    moduleVersion: String,
    moduleFid: String,
    moduleName: String,
    showInfos: Boolean,
    minWidth: undefined,
  },

  data: (self = this) => ({
    leftDrawer: true,
    rightDrawer: false,
    observer: undefined,
    show_password_change_modal: false,
    show_snackbar_success: false,
    show_snackbar_warning: false,
    show_snackbar_error: false,
    snackbar_message: '',
  }),

  computed: {
    // Breiteneinschränkung...
    toSmallClass: function () {
      switch (this.minWidth) {
        case "sm":
          return "hidden-sm-and-up";
          break;
        case "md":
          return "hidden-md-and-up";
          break;
        case "lg":
          return "hidden-lg-and-up";
          break;
        case "xl":
          return "hidden-xl-only";
          break;
        default:
          return "hidden-xs-only hidden-sm-and-up";
      }
    },
    shownClass: function () {
      switch (this.minWidth) {
        case "sm":
          return "hidden-xs-only";
          break;
        case "md":
          return "hidden-sm-and-down";
          break;
        case "lg":
          return "hidden-md-and-down";
          break;
        case "xl":
          return "hidden-lg-and-down";
          break;
        default:
          return "";
      }
    },
  },

  created() {
    let self = this;
    this.debug && console.log(`component Main created...`);

    self.$root.baseUrl = this.baseUrl;
    self.$root.rootUrl = this.rootUrl;
    self.$root.locale = self.locale;

    self.debug && console.log(self.$root.$router);
    self.debug && console.log(self.$root.$router.options);
    self.debug && console.log(self.$route);

    self.$root.$router.beforeEach((to, from, next) => {
      if (self.$refs.appBarTitle) {
        self.$refs.appBarTitle.innerHTML = "";
      }
      if (self.$refs.footerMiddle) {
        self.$refs.footerMiddle.innerHTML = "";
      }
      if (self.$refs.childMenu) {
        self.$refs.childMenu.innerHTML = "";
      }
      if (self.$refs.childDocumentation) {
        self.$refs.childDocumentation.innerHTML = "";
      }
      this.rightDrawer = false;
      next();
    });

    // possible todo: use broader event name? e.g. "show_success_snackbar_in_main_element"
    this.$eventbus.$on('infoboard_interest_subscribed', function (event) {
      self.hide_snackbar_messages()
      self.show_success_snackbar(event.message)
    })

    this.$eventbus.$on('infoboard_interest_unsubscribed', function (event) {
      self.hide_snackbar_messages()
      self.show_warning_snackbar(event.message)
    })
    
    this.$eventbus.$on('infoboard_form_important_tooltip', function (event) {
      self.hide_snackbar_messages()
      self.show_warning_snackbar(event.message)
    })

    this.$eventbus.$on('close_sidebar_left', function () {
      self.update_left_drawer(false)
    })

  },

  mounted() {
    this.observer = new MutationObserver((mutations) => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName);
        this.$nextTick(() => {
          this.onClassChange(newValue, m.oldValue);
        });
      }
    });

    this.observer.observe(document.body, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ["class"],
    });
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  methods: {
    onClassChange(classAttrValue) {
      const classList = classAttrValue.split(" ");
      if (classList.includes("sidebar-collapse")) {
        this.leftDrawer = false;
      }
    },

    open_rqm_form() {
      let url = `${this.$root.host_url}csmed/App/RQM/Form/`
      window.open(
        url,
        "target_blank",
        "height=900,width=1200"
      );
    },

    open_documentation() {
      let url = `${this.$root.host_url}csm/libraries/docs/manual/`
      window.open(url, "target_blank");
    },

    update_left_drawer(update_value) {
      this.leftDrawer = update_value;
    },

    password_changed_successfully() {
      this.show_success_snackbar(this.$t('password.changed'))
    },

    show_success_snackbar(message) {
      this.snackbar_message = message
      this.show_snackbar_success = true
    },


    show_warning_snackbar(message) {
      this.snackbar_message = message
      this.show_snackbar_warning = true
    },

    hide_snackbar_messages() {
      this.show_snackbar_success = false
      this.show_snackbar_warning = false
      this.show_snackbar_error = false
      this.snackbar_message = ''
    },

    go_to_home() {
      if (this.$route.name != 'home') {
        this.$router.push({ name: 'home' })
      }
    },
  },
};
</script>

<style>
html {
  overflow-y: auto
}

#app {
  background: #EEEEEE;
}
</style>

<style scoped>
.fade-enter-active {
  transition-duration: 1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-btn--active.no-active:not(:hover)::before {
  opacity: 0 !important;
}
</style>

<style>
#treeview-module-navigation a,
#treeview-documentation a {
  color: white;
  text-decoration: none;
  margin-left: 0.8em;
}

#treeview-module-navigation li,
#treeview-documentation li {
  margin-top: 0.6em;
  margin-right: 0em;
  margin-bottom: 0.6em;
  margin-left: 1.2em;
  list-style-type: none;
}

#treeview-module-navigation li::before,
#treeview-documentation li:before {
  content: ">";
  color: white;
  display: inline-block;
  width: 0.6em;
  margin-left: -1em;
}

#treeview-module-navigation>a,
#treeview-documentation>a {
  pointer-events: none;
  cursor: default;
}
</style>
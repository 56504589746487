<template>
  <div>
    <v-card>
      <v-card-title class="px-3 py-2 primary">
        <h3 class="white--text">{{ $t('password.change_label') }}</h3>
        <v-spacer></v-spacer>
        <v-btn text icon color="grey lighten-5" @click="$emit('close'); reset()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-spacer />
      <v-card-text class="text--primary">{{ $t('user.label') }}: {{ this.$root.user.login_name }} - {{
        this.$root.user.name
      }}</v-card-text>
      <form @submit.prevent="change_password()">
        <v-input>
          <v-row class="ma-0">
            <v-col md="12">
              <v-text-field :label="$t('password.old')" v-model="form.password_current" type="password"
                :error-messages="errors.password_current" />
            </v-col>
            <v-col md="12">
              <v-text-field :append-icon="show_password.new ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_password.new = !show_password.new" :type="show_password.new ? 'text' : 'password'"
                :label="$t('password.new')" v-model="form.password_new"
                :error-messages="errors.password_new || errors.password_new_confirmation" />
            </v-col>
            <v-col md="12">
              <v-text-field :append-icon="show_password.new_check ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show_password.new_check = !show_password.new_check"
                :type="show_password.new_check ? 'text' : 'password'" :label="$t('password.new_check')"
                v-model="form.password_new_confirmation" :error-messages="errors.password_new_confirmation" />
            </v-col>
          </v-row>
        </v-input>
        <ul v-if="errors.pattern_wrong.length > 0" dense class="red--text">
          <h4><span class="red--text">{{ $t('password.error.headline') }}:</span></h4>
          <li v-for="(error_message, index) in errors.pattern_wrong" :key="error_message"><span class="red--text">{{
            error_message
          }}</span>
            <span v-if="errors.missing_patterns.length > 0">{{ errors.missing_patterns[index] }}</span>
          </li>
        </ul>
        <v-card-actions>
          <v-btn type="submit">{{ $t('button.save') }}</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>
<script>
export default {
  data: (self = this) => ({
    request_in_progress: false,
    show_password: {
      new: false,
      new_check: false,
    },
    form: {
      password_current: undefined,
      password_new: undefined,
      password_new_confirmation: undefined,
    },
    // we have to list the error fields, otherwise the onchange listeners be attached
    errors: {
      password_current: undefined,
      password_new: undefined,
      password_new_confirmation: undefined,
      pattern_wrong: [],
      missing_patterns: []
    },
  }),

  methods: {
    change_password() {
      this.request_in_progress = true
      this.reset_errors()
      this.$http({
        method: "post",
        url: `${this.$root.app_url}/user/password/change`,
        data: this.form
      })
        .then((response) => {
          if (response.data.changed_password) {
            this.$emit('password_changed')
            this.$emit('close')
            this.reset()
          } else if (response.data.error == 'PASSWORD MISMATCH') {
            this.errors['password_current'] = [this.$t('password.error.old_password_mismatch')]
          } else if (response.data.error == 'PASSWORD CONTENT') {
            this.errors.pattern_wrong = response.data.patterns_wrong_messages
            this.errors.missing_patterns = response.data.patterns_wrong
          } else if (response.data.error == 'PASSWORD LENGTH') {
            this.errors.password_new = [response.data.message]
          }
        })
        .catch((error) => {
          this.errors = { ...this.errors, ...error.response.data }
        })
        .finally(() => {
          this.request_in_progress = false
        });
    },

    reset() {
      this.form = {}
      this.show_password.new = false
      this.show_password.new_check = false
      this.reset_errors()
    },

    reset_errors() {
      this.errors = {
        password_current: undefined,
        password_new: undefined,
        password_new_confirmation: undefined,
        pattern_wrong: [],
        missing_patterns: []
      }
    }
  }
}
</script>
<template>
  <div>
    <img :height="height" v-if="show_image" :class="`rotating_${rotation}`" :src="active_image" :alt="alt_text" />
    <v-icon v-if="show_icon" :class="`rotating_${rotation}`">{{ icon }}</v-icon>
  </div>
</template>

<script>
// TODO: pass font-size attribute for icon?
export default {
  name: "RotatingImageIcon",
  props: {
    height: undefined,
    icon: '',
    image: '',
    alt_text: "",
  },
  // could probably use computed props with passable props to allow "icon first" or "image first"?
  data: () => ({
    show_image: true,
    show_icon: false,
    rotation: 1,
    // rotation interval in ms
    // rotation interval is counted twice, e.g. 250ms * 2 = 500ms for a full rotation
    rotation_interval: 250,
    // image change interval in ms
    // image change interval should take rotation interval into account, may be irrelevant if the image change interval is much higher than the rotation interval
    image_change_interval: 10250,
  }),

  created() {
    this.add_image_change_interval()
  },

  computed: {
    active_image: function () {
      return `${this.$root.app_url}/${this.image}`
    },

    image_height: function () {
      if (this.height == undefined) {
        return -1
      } else {
        return this.height
      }
    }
  },

  methods: {
    add_image_change_interval() {
      let self = this
      window.setInterval(function () {
        self.rotation = 2;
        setTimeout(function () {
          self.rotation = 1
          self.change_image()
        }, self.rotation_interval);
      }, self.image_change_interval)
    },

    change_image() {
      if (this.show_image) {
        this.show_image = false;
        this.show_icon = true
      } else {
        this.show_image = true;
        this.show_icon = false;
      }
    },
  },
};
</script>

<style scoped>
@keyframes rotating_first_quarter {
  0% {
    transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
  }

  100% {
    transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
  }
}

@keyframes rotating_second_quarter {
  0% {
    transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
  }
}

.rotating_1 {
  -webkit-animation: rotating_first_quarter 0.25s linear;
  -moz-animation: rotating_first_quarter 0.25s linear;
  -ms-animation: rotating_first_quarter 0.25s linear;
  -o-animation: rotating_first_quarter 0.25s linear;
  animation: rotating_first_quarter 0.25s linear;
}

.rotating_2 {
  -webkit-animation: rotating_second_quarter 0.25s linear;
  -moz-animation: rotating_second_quarter 0.25s linear;
  -ms-animation: rotating_second_quarter 0.25s linear;
  -o-animation: rotating_second_quarter 0.25s linear;
  animation: rotating_second_quarter 0.25s linear;
}
</style>
<template>
  <v-snackbar v-model="show_message" color="red accent-2" top :timeout="get_timeout">
    <v-alert class="ma-0" type="error">{{ message }}</v-alert>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    message: '',
    show: false,
    timeout: Number,
  },

  computed: {
    show_message: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('close')
      }
    },

    get_timeout: {
      get() {
        if (!this.timeout) {
          return 5000 //default value https://vuetifyjs.com/en/api/v-snackbar/#props
        } else {
          return this.timeout
        }
      }
    }
  },
}
</script>
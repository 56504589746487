<template>
  <div>
    <v-card flat tile color="secondary" dark>
      <v-card-title>
        <h5>Einstellungen</h5>
      </v-card-title>
    </v-card>
    <div v-if="$root.user.permissions.infoboard && this.$route.name == 'IB'">
      <infoboard-interests />
      <v-divider light />
    </div>
    <user-info />
    <v-divider />
    <permission-list />
  </div>
</template>

<script>
import UserInfo from "components/sidebar/partials/user_info.vue"
import InfoboardInterests from "components/sidebar/partials/infoboard_interests.vue"
import PermissionList from "components/sidebar/partials/permission_list.vue"

export default {
  components: { UserInfo, InfoboardInterests, PermissionList },

}
</script>
<template>
  <div>
    <v-card flat tile color="secondary" dark>
      <v-card-title>{{ $t('menu.user_information') }}</v-card-title>
      <v-card-text>
        <p>{{ $t('user.login') }}: {{ this.$root.user.login_name }}</p>
        <p>{{ $t('user.first_and_last_name') }}: {{ this.$root.user.name }}</p>
        <p>{{ $t('user.email') }}: {{ this.$root.user.email }}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {

}
</script>
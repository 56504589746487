<template>
  <div style="line-height: 0;">
    <v-overlay :value="!loaded">
      <div class="text-center mt-4">
        <v-progress-circular :size="100" :width="7" color="blue darken-3" indeterminate />
      </div>
    </v-overlay>
    <iframe v-show="loaded" v-resize="setAvailableHeight" :src="this.url" _scrolling="no" frameborder="0" width="100%"
      :height="this.myHeight" name="content-iframe" @load="iframe_after_load">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'IFrame',
  props: {
    debug: Boolean,
    url: undefined,
  },
  data: () => ({
    loaded: false,
    myHeight: 400
  }),
  mounted() {
    this.debug && console.log(`component IFrame mounted...`)
    this.setAvailableHeight()
  },
  methods: {
    setAvailableHeight() {
      this.myHeight = window.innerHeight - this.$root.$refs.mainapp.$refs.appBar.$el.clientHeight - this.$root.$refs.mainapp.$refs.footer.$el.clientHeight
    },

    iframe_after_load(){
      this.loaded = true
      this.$eventbus.$emit('iframe_after_load')
    }
  },
}
</script>
<template>
  <div>
    <v-list-group color="grey lighten-1" v-model="is_open"
      v-if="navigation_data && navigation_data.header_element.text && navigation_data.list_elements.length > 0">
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon>{{ is_open ? header_icon_open: header_icon_closed }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ navigation_data.header_element.text }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <div v-for="(element, index) in navigation_data.list_elements" :key="index">
        <v-list-item :input-value="element.active" link :href="element.href"
          :target="element.target">
          <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ element.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list-group>
    <div v-show="false" v-html="html_tag">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initially_open: Boolean,
    html_tag: String,
    root_html_element_id: String,
    header_icon_closed: String,
    header_icon_open: String,
  },
  data() {
    return {
      is_open: true,
      root_element: '',
      navigation_data: undefined,
      observer: null,
      empty: true,
    }
  },

  created() {
    this.is_open = this.initially_open || false
  },

  methods: {
    get_navigation_data_from_injected_tree() {
      this.root_element = document.getElementById(this.root_html_element_id)
      let header_element = this.get_navigation_header_data()
      let list_elements = this.get_navigation_list_data()
      let navigation_data = {
        header_element: header_element,
        list_elements: list_elements
      }
      
      let navigation_empty = Object.keys(header_element).length == 0 && list_elements.length == 0
      
      this.empty = navigation_empty
      this.$emit('empty_state_changed', this.empty)
    
      this.navigation_data = navigation_data
      return navigation_data
    },

    get_navigation_header_data() {
      let header = {}
      let anchor_elements_collection = this.root_element.getElementsByTagName('a')

      if (anchor_elements_collection.length > 0) {
        let header_anchor = anchor_elements_collection[0]
        header = {
          href: header_anchor.attributes.href,
          text: header_anchor.text
        }
      }
      return header
    },

    get_navigation_list_data() {
      let list_data = []
      let unordered_list = this.root_element.getElementsByTagName('ul')

      if (unordered_list.length > 0) {
        let anchors_in_list = unordered_list[0].getElementsByTagName('a')
        for (let anchor of anchors_in_list) {
          let href = ''
          if (anchor.attributes.href) {
            href = anchor.attributes.href.value
          }
          let target = ''
          if (anchor.attributes.target) {
            target = anchor.attributes.target.value
          }
          list_data.push({
            href: href,
            text: anchor.text,
            active: anchor.parentElement.classList.contains('active'),
            target: target
          })
        };
      }
      return list_data
    }
  },

  mounted() {
    let target = document.getElementById(this.root_html_element_id)
    let options = {
      subtree: true,
      childList: true,
      attributes: true,
    }

    this.observer = new MutationObserver((mutationList, observer) => {
      this.get_navigation_data_from_injected_tree()
    });

    this.observer.observe(target, options);

    this.get_navigation_data_from_injected_tree()
  },

  beforeUnmount() {
    this.observer && this.observer.disconnect()
  },
}


</script>
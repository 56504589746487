<template>
  <div>
    <v-list-item v-if="module.user_has_permission" link :to="{ name: module.name_short }" class="my-2 px-3">
      <v-list-item-icon style="height: unset" :class="`${module.icon.color} pa-2 mr-3 rounded-circle`">
        <v-icon small>{{ module.icon.name }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="pa-0">
        <v-list-item-title>
          {{ module.name_short }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ module.name }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-else>
      <v-list-item-icon style="height: unset" :class="`${module.icon.color} pa-2 mr-3 rounded-circle`">
        <v-icon small>{{ module.icon.name }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="pa-0">
        <v-list-item-title>
          {{ module.name_short }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ module.name }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
export default {
  props: {
    module: Object,
  },
};
</script>